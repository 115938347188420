import {
  Dialog,
  FeedbackBanner,
  Flex,
  InputField,
  LoadingIndicator,
  Spacer,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useRootStore } from '../../stores/RootStoreContext'
import './CreateNewSpaceDialog.scss'

interface CreateNewSpaceDialogProps {
  isOpen: boolean
  onClose: () => void
}

const CreateNewSpaceDialog = (props: CreateNewSpaceDialogProps) => {
  const { spacesStore } = useRootStore()

  const [spaceName, setSpaceName] = useState<string>('')
  const [spaceDescription, setSpaceDescription] = useState<string>('')

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const createSpace = async () => {
    if (spaceName.trim() === '') {
      setErrorMessage('Du musst einen Namen für den Space eingeben.')
      return
    }
    try {
      setErrorMessage(undefined)
      setIsLoading(true)
      await spacesStore.createSpace({
        name: spaceName,
        description: spaceDescription,
      })
      props.onClose()
    } catch (error) {
      setErrorMessage(
        error.message ??
          'Der Space konnte nicht erstellt werden. Versuche es erneut.'
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Dialog
        title='Space erstellen'
        isOpen={props.isOpen}
        onDismiss={props.onClose}
        dismissable
        initialFocusIndex={1}
        icon='archive'
        actions={[
          {
            children: 'Abbrechen',
            onClick: () => props.onClose(),
            variant: 'tertiary',
          },
          {
            children: 'Erstellen',
            onClick: () => createSpace(),
            variant: 'primary',
          },
        ]}
      >
        <Flex direction='vertical'>
          <InputField label='Titel' value={spaceName} onChange={setSpaceName} />
          <InputField
            label='Beschreibung (optional)'
            value={spaceDescription}
            onChange={setSpaceDescription}
          />
        </Flex>
        {errorMessage && (
          <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
        )}
      </Dialog>
      <Dialog isOpen={isLoading}>
        <Spacer direction='vertical' />
        <LoadingIndicator loadingText='Space wird erstellt.' />
        <Spacer direction='vertical' />
      </Dialog>
    </>
  )
}

export default observer(CreateNewSpaceDialog)
