import { NonIdealState } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import TaskModel from '../../model/Task'
import { useRootStore } from '../../stores/RootStoreContext'
import Task, { TaskProps } from './Task'

export interface TasksListProps extends Partial<TaskProps> {
  filterFunction?: TasksFilterFunction | PredefinedTasksFilterFunction
  sortingFunction?: (a: TaskModel, b: TaskModel) => number
}

export type TasksFilterFunction = (task: TaskModel) => boolean
export type PredefinedTasksFilterFunction = 'me' | 'done' | 'due' | 'active'

const TasksList = (props: TasksListProps) => {
  const { tasksStore, authenticationStore } = useRootStore()

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const filterDone: TasksFilterFunction = (task) => task.status === 'done'
  const filterAssignedToMe: TasksFilterFunction = (task) =>
    task.assignee === authenticationStore.currentUser?.id && !filterDone(task)
  const filterDue: TasksFilterFunction = (task) =>
    !!task.dueDate && new Date(task.dueDate) <= today && !filterDone(task)
  const filterActive: TasksFilterFunction = (task) => !filterDone(task)

  const filterFunction = () => {
    switch (props.filterFunction) {
      case 'me':
        return filterAssignedToMe
      case 'due':
        return filterDue
      case 'done':
        return filterDone
      case 'active':
        return filterActive
      default:
        return props.filterFunction ?? (() => true)
    }
  }

  const tasks = tasksStore.tasks?.filter(filterFunction()).sort(
    props.sortingFunction ??
      ((a, b) => {
        if (!a.dueDate) return 1
        if (!b.dueDate) return -1

        return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
      })
  )

  return (
    <div className='tasks-list'>
      {tasks?.map((task) => (
        <Task key={task.id} {...props} task={task} />
      ))}
      {tasks?.length === 0 && (
        <NonIdealState className='no-tasks' title='' icon='check'>
          Alles erledigt
        </NonIdealState>
      )}
    </div>
  )
}

export default observer(TasksList)
