import { ApplicationFrame } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import AuthenticationPage from './pages/AuthenticationPage'
import AddFilePage from './pages/files/AddFilePage'
import FilesPage from './pages/files/FilesPage'
import Home from './pages/home/Home'
import NotFoundPage from './pages/NotFoundPage'
import EditPostPage from './pages/posts/EditPostPage'
import PostEditor from './pages/posts/PostEditor'
import PostPage from './pages/posts/PostPage'
import PostsPage from './pages/posts/PostsPage'
import SpaceNavigationItem from './pages/space/SpaceNavigationItem'
import SpacePage from './pages/space/SpacePage'
import TasksPage from './pages/tasks/TasksPage'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import UnauthenticatedRoute from './routes/UnauthenticatedRoute'
import { useRootStore } from './stores/RootStoreContext'

const ApplicationSwitch = () => {
  const { authenticationStore } = useRootStore()
  const history = useHistory()
  const location = useLocation()

  return (
    <ApplicationFrame
      appTitle='Spaces'
      identifier='spaces'
      userMenu={authenticationStore.userMenu}
      history={history}
      location={location}
      headerProps={{
        onHomeClick: () => {
          document.location.href = 'https://staging.infinity.nextbusiness.ch/'
        },
      }}
    >
      <div className='application-switch'>
        <Switch>
          <AuthenticatedRoute
            path='/'
            exact
            redirectToIfUnauthenticated='/login'
          >
            <Redirect to='/home' />
          </AuthenticatedRoute>
          <UnauthenticatedRoute path='/login' exact>
            <AuthenticationPage />
          </UnauthenticatedRoute>
          <AuthenticatedRoute path='/home' exact>
            <Home />
          </AuthenticatedRoute>
          <AuthenticatedRoute path='/space/:spaceId'>
            <SpaceNavigationItem />
            <Switch>
              <AuthenticatedRoute
                path='/space/:spaceId'
                exact
                render={(props) => (
                  <Redirect
                    to={`/space/${props.match.params.spaceId}/overview`}
                  />
                )}
              />
              <AuthenticatedRoute path='/space/:spaceId/overview' exact>
                <SpacePage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path='/space/:spaceId/posts' exact>
                <PostsPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path='/space/:spaceId/tasks' exact>
                <TasksPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path='/space/:spaceId/files' exact>
                <FilesPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path='/space/:spaceId/files/new' exact>
                <AddFilePage />
              </AuthenticatedRoute>
              <AuthenticatedRoute path='/space/:spaceId/posts/new' exact>
                <PostEditor />
              </AuthenticatedRoute>
              <AuthenticatedRoute path='/space/:spaceId/post/:postId' exact>
                <PostPage />
              </AuthenticatedRoute>
              <AuthenticatedRoute
                path='/space/:spaceId/post/:postId/edit'
                exact
              >
                <EditPostPage />
              </AuthenticatedRoute>
            </Switch>
          </AuthenticatedRoute>
          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </div>
    </ApplicationFrame>
  )
}

export default observer(ApplicationSwitch)
