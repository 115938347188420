import { action, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import Task from '../model/Task'
import { BackendError, ErrorType } from '../networking/Errors'
import Tasks from '../networking/Tasks'
import RootStore from './RootStore'
import Store from './Store'

export default class TasksStore extends Store {
  @persist('list') @observable tasks: Task[] | null = []
  @persist @observable hasTasksPermission: boolean = true

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  async loadTasks() {
    if (!this.rootStore.organisationStore.currentIdentifier) return
    try {
      const tasks = await Tasks.tasksForOrganisation(
        this.rootStore.organisationStore.currentIdentifier
      )
      runInAction(() => {
        this.tasks = tasks
        this.hasTasksPermission = true
      })
    } catch (error) {
      const errorType = (error as BackendError).type
      if (
        errorType === ErrorType.InsufficientPermissions ||
        errorType === ErrorType.Unauthorised
      ) {
        this.hasTasksPermission = false
      }
    }
  }

  @action
  async updateTaskWithId(taskId: string, changes: Partial<Task>) {
    if (this.tasks)
      this.tasks = this.tasks.map((task) =>
        task.id === taskId ? { ...task, ...changes } : task
      )

    await Tasks.updateTaskWithId(
      taskId,
      this.rootStore.organisationStore.currentIdentifier!,
      changes
    )
    await this.loadTasks()
  }

  @action
  async deleteTaskWithId(taskId: string) {
    if (this.tasks) this.tasks = this.tasks.filter((task) => task.id !== taskId)

    await Tasks.deleteTaskWithId(
      taskId,
      this.rootStore.organisationStore.currentIdentifier!
    )
    await this.loadTasks()
  }

  @action
  reset() {
    this.tasks = []
  }
}
