import Post from '../model/Post'
import { accessQueryParameters } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import { BASE_URL, DEFAULT_HEADERS } from './Spaces'

/**
 * Gets all spaces of the current organisation
 * @param organisationIdentifier ID of the organisation from which the app was started
 */
const listPosts = async (
  organisationIdentifier: string,
  spaceIdentifier: string
): Promise<Post[]> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/posts${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'GET', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.posts) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.posts as Post[]
}

/**
 * Gets the post with the given ID
 * @param organisationIdentifier ID of the organisation from which the app was started
 */
const getPost = async (
  organisationIdentifier: string,
  spaceIdentifier: string,
  postIdentifier: string
): Promise<Post> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/post/${postIdentifier}${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'GET', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.post) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.post as Post
}

/**
 * Creates a new space from a given payload
 * @param space New space that will be created
 * @param organisationIdentifier ID of the organisation from which the app was started
 */
const createPost = async (
  post: Partial<Post>,
  organisationIdentifier: string,
  spaceIdentifier: string
): Promise<Post> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/posts${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'POST', ...DEFAULT_HEADERS, body: JSON.stringify(post) }
  )
  const body = await response.json()

  if (!response.ok || !body.post) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.post as Post
}

/**
 * Updates an existing post
 */
const updatePost = async (
  changes: Partial<Post>,
  postIdentifier: string,
  organisationIdentifier: string,
  spaceIdentifier: string
): Promise<Post> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/post/${postIdentifier}${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'PATCH', ...DEFAULT_HEADERS, body: JSON.stringify(changes) }
  )
  const body = await response.json()

  if (!response.ok || !body.post) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.post as Post
}

/**
 * Deletes an existing post
 */
const deletePost = async (
  postIdentifier: string,
  organisationIdentifier: string,
  spaceIdentifier: string
): Promise<Post> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/post/${postIdentifier}${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'DELETE', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.post) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.post as Post
}

const Posts = {
  listPosts,
  getPost,
  createPost,
  updatePost,
  deletePost,
}

export default Posts
