import { makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import Space from '../model/Space'
import Spaces from '../networking/Spaces'
import RootStore from './RootStore'
import Store from './Store'

export default class SpacesStore extends Store {
  @persist('list') @observable spaces: Space[] = []

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  async loadSpaces() {
    if (!this.rootStore.authenticationStore.currentUser) return
    if (!this.rootStore.authenticationStore.organisationIdentifier) return

    const spaces = await Spaces.listSpaces(
      this.rootStore.authenticationStore.organisationIdentifier!
    )
    runInAction(() => {
      this.spaces = this.temporarilyAndUnsafelyFilterSpaces(spaces)
    })
  }

  private temporarilyAndUnsafelyFilterSpaces(spaces: Space[]) {
    /**
     * TODO: This will be replaced with server-side logic once the
     * user data token logic is fully done
     */
    if (!this.rootStore.authenticationStore.currentUser) return []
    return (
      spaces.filter(
        (space) =>
          !space.hasRestrictedAccess ||
          space.members?.includes(
            this.rootStore.authenticationStore.currentUser!.id
          )
      ) ?? []
    )
  }

  async createSpace(space: Partial<Space>) {
    if (!this.rootStore.authenticationStore.organisationIdentifier) return

    const createdSpace = await Spaces.createSpace(
      space,
      this.rootStore.authenticationStore.organisationIdentifier!
    )
    runInAction(() => {
      this.spaces.push(createdSpace)
    })
  }

  async updateSpace(spaceId: string, changes: Partial<Space>) {
    if (!this.rootStore.authenticationStore.organisationIdentifier) return

    const updatedSpace = await Spaces.updateSpace(
      spaceId,
      changes,
      this.rootStore.authenticationStore.organisationIdentifier!
    )
    runInAction(() => {
      this.spaces = this.spaces.map((space) =>
        space.id === spaceId ? updatedSpace : space
      )
    })
  }

  async deleteSpace(spaceId: string) {
    if (!this.rootStore.authenticationStore.organisationIdentifier) return

    await Spaces.deleteSpace(
      spaceId,
      this.rootStore.authenticationStore.organisationIdentifier!
    )
    runInAction(() => {
      this.spaces = this.spaces.filter((space) => space.id !== spaceId)
    })
  }

  onHydrate() {
    this.loadSpaces()
  }
}
