import { Avatar, Flex } from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'
import React from 'react'
import Task from '../../model/Task'
import { useRootStore } from '../../stores/RootStoreContext'
import DueDateText from './DueDateText'
import './TaskGlances.scss'

interface TaskGlancesProps {
  task: Task
}

const TaskGlances = ({ task }: TaskGlancesProps) => {
  const { organisationStore } = useRootStore()

  return (
    <Flex className='task-glance' verticalAlignment='center'>
      {task.dueDate && (
        <Flex
          className='glance-item glance-due-date'
          verticalAlignment='center'
          spacing='tiny'
        >
          <DueDateText
            date={DateTime.fromISO(task.dueDate as string).toFormat('dd.LL.y')}
            isDone={task.status === 'done'}
          />
        </Flex>
      )}
      {task.assignee && (
        <Flex
          className='glance-item glance-assignee'
          verticalAlignment='center'
        >
          <Avatar name={organisationStore.fullNameOfMember(task.assignee)} />
          {
            organisationStore.members?.find(
              (member) => member.id === task.assignee
            )?.firstname
          }
        </Flex>
      )}
    </Flex>
  )
}

export default TaskGlances
