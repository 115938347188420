import { Flex, Avatar, Button } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Space from '../../model/Space'
import { useRootStore } from '../../stores/RootStoreContext'
import ManageSpaceAccessDialog from './ManageSpaceAccessDialog'

interface SpaceTeamMembersProps {
  space: Space
}

const SpaceTeamMembers = (props: SpaceTeamMembersProps) => {
  const { organisationStore } = useRootStore()
  const [
    isManageAccessDialogOpen,
    setIsManageAccessDialogOpen,
  ] = useState<boolean>(false)

  return (
    <>
      <Flex
        horizontalAlignment='flex-start'
        verticalAlignment='center'
        className='space-people'
      >
        {props.space.hasRestrictedAccess ? (
          <>
            {props.space.members?.map((member: string) => (
              <Avatar
                name={organisationStore.fullNameOfMember(member)}
                key={member}
              />
            ))}
            <Button
              iconOnly='add'
              variant='quaternary'
              onClick={() => setIsManageAccessDialogOpen(true)}
            />
          </>
        ) : (
          <Button
            iconLeft='team'
            variant='quaternary'
            onClick={() => setIsManageAccessDialogOpen(true)}
          >
            Für alle zugänglich
          </Button>
        )}
      </Flex>
      <ManageSpaceAccessDialog
        isOpen={isManageAccessDialogOpen}
        setIsOpen={setIsManageAccessDialogOpen}
        space={props.space}
      />
    </>
  )
}

export default observer(SpaceTeamMembers)
