import { FeedbackBanner } from '@nextbusiness/infinity-ui'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Post from '../../model/Post'
import Posts from '../../networking/Posts'
import { useRootStore } from '../../stores/RootStoreContext'
import PostEditor from './PostEditor'

const EditPostPage = () => {
  const { spaceId, postId } = useParams<{ spaceId: string; postId: string }>()
  const { authenticationStore, spacesStore } = useRootStore()

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  const [post, setPost] = useState<Post>()

  useEffect(() => {
    if (!space || !authenticationStore.organisationIdentifier) return

    Posts.getPost(
      authenticationStore.organisationIdentifier,
      spaceId,
      postId
    ).then((loadedPost) => setPost(loadedPost))
  }, [])

  if (!space || !post) return null

  let content: any
  let hasError: boolean = false

  try {
    content = JSON.parse(post.content!)
  } catch {
    hasError = true
  }
  if (hasError) {
    return <FeedbackBanner>Ein Fehler ist aufgetreten.</FeedbackBanner>
  }

  return <PostEditor existingPost={post} existingContent={content} />
}

export default EditPostPage
