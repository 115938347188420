import {
  Avatar,
  Button,
  Card,
  Dialog,
  FeedbackBanner,
  Flex,
  Option,
  RadioGroup,
  Select,
  Spacer,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Space from '../../model/Space'
import { useRootStore } from '../../stores/RootStoreContext'
import './ManageSpaceAccessDialog.scss'

interface ManageSpaceAccessDialogProps {
  space: Space
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const ACCESS_OPTIONS = [
  { label: 'Alle in der Organisation', value: false },
  { label: 'Nur bestimmte Personen', value: true },
]

const ManageSpaceAccessDialog = (props: ManageSpaceAccessDialogProps) => {
  const { authenticationStore, organisationStore, spacesStore } = useRootStore()
  const [hasRestrictedAccess, setHasRestrictedAccess] = useState<boolean>(
    props.space.hasRestrictedAccess || false
  )
  const [members, setMembers] = useState<string[]>(
    (props.space.members?.length || 0) > 0
      ? props.space.members!
      : [authenticationStore.currentUser!.id]
  )

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()

  const saveSpaceAccessOptions = async () => {
    try {
      await spacesStore.updateSpace(
        props.space.id,
        hasRestrictedAccess
          ? { hasRestrictedAccess: true, members: members }
          : { hasRestrictedAccess: false }
      )
    } catch (error) {
      setErrorMessage(
        error.message ??
          'Ein Fehler beim Speichern ist aufgetreten. Bitte versuche es erneut.'
      )
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Dialog
      title='Zugriff verwalten'
      isOpen={props.isOpen}
      onDismiss={() => props.setIsOpen(false)}
      dismissable
      icon='lock'
      actions={[
        {
          children: 'Speichern',
          variant: 'primary',
          disabled: isSaving || (hasRestrictedAccess && members.length === 0),
          onClick: async () => {
            setIsSaving(true)
            await saveSpaceAccessOptions()
            props.setIsOpen(false)
          },
        },
      ]}
      className='manage-space-access-dialog'
    >
      <Text type='paragraph'>
        Wer soll Zugriff auf den Space <b>{props.space.name}</b> haben?
      </Text>
      <Spacer size='tiny' />
      <RadioGroup
        options={ACCESS_OPTIONS}
        onChange={setHasRestrictedAccess}
        value={hasRestrictedAccess}
      />
      <Spacer size='tiny' />
      {hasRestrictedAccess && (
        <Card elevation='none'>
          <Flex direction='vertical' spacing='tiny'>
            <Flex spacing='tiny'>
              <div style={{ flexGrow: 1 }}>
                <Select
                  options={organisationStore.membersAsOptions.filter(
                    (option) => !members.includes(option.value as string)
                  )}
                  placeholder='Mitglied hinzufügen'
                  value={null}
                  onChange={(option: Option) =>
                    setMembers([...members, option.value as string])
                  }
                />
              </div>
            </Flex>
            <div className='members-list'>
              {members.map((member) => {
                const name = organisationStore.fullNameOfMember(member)
                return (
                  <Flex
                    className='member-row'
                    verticalAlignment='center'
                    spacing='tiny'
                    key={member}
                  >
                    <Avatar name={name} />
                    <Text type='inline' style={{ flexGrow: 1 }}>
                      {name}
                    </Text>
                    {member !== authenticationStore.currentUser?.id && (
                      <Button
                        iconOnly='delete'
                        variant='quaternary'
                        onClick={() =>
                          setMembers(
                            members.filter(
                              (filteredMember) => filteredMember !== member
                            )
                          )
                        }
                      />
                    )}
                  </Flex>
                )
              })}
            </div>
          </Flex>
          {errorMessage && (
            <FeedbackBanner variant='error'>{errorMessage}</FeedbackBanner>
          )}
        </Card>
      )}
    </Dialog>
  )
}

export default observer(ManageSpaceAccessDialog)
