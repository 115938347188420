export enum ErrorType {
  Unauthorised,
  TooManyRequests,
  Conflict,
  InvalidCredentials,
  MissingRequiredFields,
  VerificationPending,
  NotFound,
  InsufficientSubscription,
  InsufficientPermissions,
}

export class BackendError extends Error {
  type: ErrorType
  additionalData?: any

  constructor(type: ErrorType, message?: string, additionalData?: any) {
    super(message)
    this.type = type
    this.additionalData = additionalData
  }
}
