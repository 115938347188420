import { Checkbox } from '@nextbusiness/infinity-ui'
import React from 'react'
import TaskModel from '../../model/Task'

interface TaskStatusProps {
  task: TaskModel
  updateTask: (changes: Partial<TaskModel>) => Promise<void>
}

const TaskStatus = ({ task, updateTask }: TaskStatusProps) => (
  <Checkbox
    isChecked={task.status === 'done'}
    onChange={(isChecked) =>
      updateTask({ status: isChecked ? 'done' : 'icebox' })
    }
  />
)

export default TaskStatus
