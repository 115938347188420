import { Button, NavigationItem } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useRootStore } from '../../stores/RootStoreContext'
import CreateNewSpaceDialog from './CreateNewSpaceDialog'
import './Home.scss'
import SpaceCard from './SpaceCard'

const Home = () => {
  const { spacesStore, organisationStore } = useRootStore()
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    spacesStore.loadSpaces()
  }, [organisationStore.currentIdentifier])

  return (
    <>
      <NavigationItem />
      <div className='spaces-home'>
        <div className='spaces-actions'>
          <Button
            iconLeft='add'
            onClick={() => setIsCreateDialogOpen(true)}
            variant='primary'
          >
            Neuen Space erstellen
          </Button>
        </div>
        {spacesStore.spaces.map((space) => (
          <SpaceCard space={space} key={space.id} />
        ))}
      </div>
      <CreateNewSpaceDialog
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
      />
    </>
  )
}

export default observer(Home)
