import { Icon, Text, Tooltip } from '@nextbusiness/infinity-ui'
import React from 'react'
import InlineEdit from '../input/InlineEdit'
import TaskModel from '../../model/Task'

interface TaskFlairProps {
  task: TaskModel
  updateTask: (changes: Partial<TaskModel>) => Promise<void>
}

const TaskFlair = ({ task, updateTask }: TaskFlairProps) => (
  <InlineEdit
    mode='popup'
    initialValue={task.flair ?? ''}
    renderValue={(flair: string) =>
      flair ? (
        <Text className='flair-text' type='inline'>
          {flair}
        </Text>
      ) : (
        <Tooltip content='Tag setzen'>
          <Icon icon='bookmark' size={20} />
        </Tooltip>
      )
    }
    onCommit={async (flair: string) => {
      await updateTask({ flair })
    }}
    className='task-flair'
  />
)

export default TaskFlair
