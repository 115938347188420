import {
  Flex,
  Icon,
  IconSize,
  NonIdealState,
  Text,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import File from '../../model/File'
import Space from '../../model/Space'
import Files from '../../networking/Files'
import { useRootStore } from '../../stores/RootStoreContext'
import './FilesList.scss'

interface FilesListProps {
  space: Space
  limit?: number
}

const FilesList = (props: FilesListProps) => {
  const { authenticationStore } = useRootStore()
  const [files, setFiles] = useState<File[]>([])

  useEffect(() => {
    if (!props.space || !authenticationStore.organisationIdentifier) return

    Files.listFiles(
      authenticationStore.organisationIdentifier,
      props.space.id
    ).then((loadedFiles) => {
      if (props.limit && props.limit < loadedFiles.length) {
        setFiles(loadedFiles.slice(0, props.limit))
      } else {
        setFiles(loadedFiles)
      }
    })
  }, [])

  if (!props.space) return null

  return (
    <div className='files-list'>
      {files.length === 0 && (
        <NonIdealState icon='folder'>Noch keine Dateien</NonIdealState>
      )}
      {files.map((file, index) => (
        <React.Fragment key={file.id}>
          {index > 0 && <div className='delimiter' />}
          <a
            href={file.blobURL}
            target='_blank'
            rel='noreferrer noopener'
            className='file-entry'
            key={file.id}
          >
            <Flex verticalAlignment='center'>
              <Icon size={IconSize.Tiny} icon='document' />
              <Text type='inline' className='title'>
                {file.fileName}
              </Text>
            </Flex>
          </a>
        </React.Fragment>
      ))}
    </div>
  )
}

export default observer(FilesList)
