import {
  Avatar,
  Button,
  Card,
  FeedbackBanner,
  Flex,
  Heading,
  Text,
} from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import EditorJs from 'react-editor-js'
import { useHistory, useParams } from 'react-router-dom'
import Post from '../../model/Post'
import Posts from '../../networking/Posts'
import { useRootStore } from '../../stores/RootStoreContext'
import { EDITOR_JS_TOOLS } from './EditorTools'

const PostPage = () => {
  const { spaceId, postId } = useParams<{ spaceId: string; postId: string }>()
  const { authenticationStore, spacesStore, organisationStore } = useRootStore()
  const history = useHistory()

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  const [post, setPost] = useState<Post>()

  useEffect(() => {
    if (!space || !authenticationStore.organisationIdentifier) return

    Posts.getPost(
      authenticationStore.organisationIdentifier,
      spaceId,
      postId
    ).then((loadedPost) => setPost(loadedPost))
  }, [])

  if (!space || !post) return null

  let content: any
  let hasError: boolean = false

  try {
    content = JSON.parse(post.content!)
  } catch {
    hasError = true
  }

  return (
    <div className='space-card-page posts-page'>
      <Card elevation='medium' className='space-subpage with-top-bar-actions'>
        <Flex className='page-actions'>
          <Button
            variant='tertiary'
            iconLeft='edit'
            onClick={() =>
              history.push(`/space/${spaceId}/post/${postId}/edit`)
            }
          >
            Bearbeiten
          </Button>
        </Flex>
        <Flex verticalAlignment='center' direction='vertical'>
          <Avatar
            name={organisationStore.fullNameOfMember(post.author)}
            className='post-author'
          />
          <Heading type='h1'>{post.title}</Heading>
          <Text type='inline' variant='subtle' className='post-date'>
            {DateTime.fromISO(post.createdAt).toLocaleString({
              month: 'long',
              day: 'numeric',
              weekday: 'long',
            })}
          </Text>
        </Flex>
        <div className='post-editor viewer'>
          {hasError ? (
            <FeedbackBanner variant='error' title='Fehler beim Laden'>
              Der Inhalt konnte nicht geladen werden.
            </FeedbackBanner>
          ) : (
            <EditorJs data={content} tools={EDITOR_JS_TOOLS} readOnly />
          )}
        </div>
      </Card>
    </div>
  )
}

export default PostPage
