import { Button, Card, Flex, InputField, Text } from '@nextbusiness/infinity-ui'
import React, { useEffect, useState } from 'react'
import './InlineEdit.scss'

export interface InlineEditControl {
  value: any
  onChange: (value: any) => void
}

export interface InlineEditProps {
  initialValue: any
  onCommit?: (value: any) => Promise<void>
  onCancel?: () => void
  inputControl?: React.ReactElement<InlineEditControl>
  renderValue?: (value: any) => React.ReactNode
  onActivate?: () => void
  shouldDeactivate?: boolean
  style?: React.CSSProperties
  className?: string
  disabled?: boolean
  mode?: InlineEditMode
}

type InlineEditMode = 'default' | 'popup'

const InlineEdit = (props: InlineEditProps) => {
  const [isEditingField, setIsEditingField] = useState<any>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<any>(props.initialValue)

  const resetFields = () => {
    setCurrentValue(props.initialValue)
    setIsEditingField(false)
    setIsSaving(false)
    if (props.onCancel) props.onCancel()
  }

  useEffect(() => {
    if (props.shouldDeactivate) resetFields()
  }, [props.shouldDeactivate])

  const value = props.renderValue ? (
    props.renderValue(props.initialValue)
  ) : (
    <Text type='inline'>{props.initialValue}</Text>
  )

  const inputButton = (
    <Button
      variant='quaternary'
      className={'input-button' + (isEditingField ? ' active' : '')}
      onClick={() => {
        resetFields()
        if (!isEditingField) {
          if (props.onActivate) props.onActivate()
          setIsEditingField(true)
        }
      }}
    >
      {value}
    </Button>
  )

  return (
    <div
      className={'inline-edit' + (props.className ? ' ' + props.className : '')}
      style={props.style}
    >
      {props.disabled ? (
        value
      ) : isEditingField ? (
        <>
          {props.mode === 'popup' && inputButton}
          <div
            className={
              'inline-edit-field' +
              (props.mode === 'popup' ? ' popup-mode' : '')
            }
          >
            {props.inputControl ? (
              React.cloneElement(props.inputControl, {
                value: currentValue,
                onChange: setCurrentValue,
              })
            ) : (
              <InputField
                value={currentValue}
                onChange={setCurrentValue}
                className='input-field'
              />
            )}
            <Flex className='inline-edit-actions' spacing='tiny'>
              <Card elevation='low'>
                <Button
                  iconOnly='check'
                  variant='secondary'
                  onClick={async () => {
                    setIsSaving(true)
                    if (props.onCommit) await props.onCommit(currentValue)
                    resetFields()
                  }}
                />
              </Card>
              <Card elevation='low'>
                <Button
                  iconOnly='dismiss'
                  variant='tertiary'
                  onClick={() => resetFields()}
                />
              </Card>
              {isSaving && (
                <Text type='inline' variant='subtle'>
                  Speichern...
                </Text>
              )}
            </Flex>
          </div>
        </>
      ) : (
        inputButton
      )}
    </div>
  )
}

export default InlineEdit
