import {
  Button,
  Card,
  Flex,
  InputField,
  Spacer,
} from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import EditorJs from 'react-editor-js'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import Post from '../../model/Post'
import Posts from '../../networking/Posts'
import { useRootStore } from '../../stores/RootStoreContext'
import { EDITOR_JS_TOOLS } from './EditorTools'
import './PostEditor.scss'

interface PostEditorProps {
  existingPost?: Post
  existingContent?: any
}

const PostEditor = (props: PostEditorProps) => {
  const { spaceId } = useParams<{ spaceId: string }>()
  const { authenticationStore, spacesStore } = useRootStore()

  const history = useHistory()
  const [currentDraft, setCurrentDraft] = useState<any>(
    props.existingContent ?? {}
  )
  const [currentTitle, setCurrentTitle] = useState<string>(
    props.existingPost?.title ?? 'Unbenannt'
  )

  const publish = async () => {
    let post: Post
    if (!props.existingPost) {
      post = await Posts.createPost(
        {
          title: currentTitle,
          content: JSON.stringify(currentDraft),
          author: authenticationStore.currentUser?.id ?? 'guest',
        },
        authenticationStore.organisationIdentifier!,
        spaceId
      )
      history.push(`/space/${spaceId}/post/${post.id}`)
    } else {
      post = await Posts.updatePost(
        {
          title: currentTitle,
          content: JSON.stringify(currentDraft),
        },
        props.existingPost.id,
        authenticationStore.organisationIdentifier!,
        spaceId
      )
    }
    history.push(`/space/${spaceId}/post/${post.id}`)
  }

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  if (!space) return null

  return (
    <div className='space-card-page post-composer-page'>
      <Card elevation='medium' className='space-subpage'>
        <div className='subpage-container'>
          <Flex
            verticalAlignment='center'
            direction='vertical'
            className='composer-header'
          >
            <InputField
              className='title-field'
              value={currentTitle}
              onChange={setCurrentTitle}
              label='Titel'
              fullWidth
            />
          </Flex>
          <div className='post-editor'>
            <EditorJs
              data={currentDraft}
              onChange={(_: EditorJS.API, output?: EditorJS.OutputData) =>
                setCurrentDraft(output)
              }
              tools={EDITOR_JS_TOOLS}
              placeholder='Text hier eingeben…'
            />
          </div>
          <Flex spacing='tiny' className='composer-actions'>
            <Button iconLeft='news' onClick={() => publish()} variant='primary'>
              {props.existingPost
                ? 'Änderungen veröffentlichen'
                : 'Beitrag veröffentlichen'}
            </Button>
            <Button
              iconLeft='delete'
              variant='tertiary'
              onClick={() => history.push('./')}
            >
              Verwerfen
            </Button>
          </Flex>
          <Spacer size='regular' />
        </div>
      </Card>
    </div>
  )
}

export default observer(PostEditor)
