import {
  Button,
  Card,
  Flex,
  Heading,
  InputField,
} from '@nextbusiness/infinity-ui'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import Files from '../../networking/Files'
import { useRootStore } from '../../stores/RootStoreContext'

const AddFilePage = () => {
  const { spaceId } = useParams<{ spaceId: string }>()
  const { authenticationStore, spacesStore, organisationStore } = useRootStore()
  const history = useHistory()

  const [currentFile, setCurrentFile] = useState<any>()
  const [currentFileName, setCurrentFileName] = useState<string>('')

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  if (!space) return null

  const uploadFile = async () => {
    const data = new FormData()

    data.append('file', currentFile)
    data.append('fileName', currentFileName)
    data.append('uploader', authenticationStore.currentUser?.id ?? 'guest')

    await Files.uploadFile(data, organisationStore.currentIdentifier!, space.id)
    history.push(`/space/${spaceId}/files`)
  }

  return (
    <div className='space-card-page files-page'>
      <Card elevation='medium' className='space-subpage'>
        <div className='subpage-container'>
          <Flex verticalAlignment='center' direction='vertical'>
            <Heading type='h1'>Datei hochladen</Heading>
          </Flex>
          <Flex
            direction='vertical'
            spacing='regular'
            verticalAlignment='center'
          >
            <input
              type='file'
              onChange={(event) => {
                if ((event.target.files?.length ?? 0) > 0) {
                  const file = event.target.files![0]

                  setCurrentFile(file)
                  setCurrentFileName(file.name)
                }
              }}
            />
            <InputField
              label='Datei benennen'
              value={currentFileName}
              onChange={setCurrentFileName}
            />
            <Button
              disabled={!currentFile || !currentFileName}
              onClick={() => uploadFile()}
            >
              Hochladen
            </Button>
          </Flex>
        </div>
      </Card>
    </div>
  )
}

export default AddFilePage
