import { NonIdealState } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { Permission } from '../../base-components/system/Permission'
import RequestPermission from '../../base-components/system/RequestPermission'
import TasksList from '../../base-components/tasks/TasksList'
import Space from '../../model/Space'
import { useRootStore } from '../../stores/RootStoreContext'

interface TasksToolProps {
  space: Space
}

const TasksTool = (props: TasksToolProps) => {
  const { tasksStore, authenticationStore } = useRootStore()
  const [
    shouldRequestPermission,
    setShouldRequestPermission,
  ] = useState<boolean>(false)

  if (!tasksStore.hasTasksPermission)
    return (
      <>
        <NonIdealState
          icon='privilege'
          actions={[
            {
              children: 'Zugriff erlauben',
              onClick: () => setShouldRequestPermission(true),
            },
          ]}
        >
          Infinity Spaces hat keinen Zugriff auf Tasks
        </NonIdealState>
        {shouldRequestPermission && (
          <RequestPermission
            permissions={[Permission.ReadTasks, Permission.WriteTasks]}
            onPermissionGranted={() => authenticationStore.logout()}
          />
        )}
      </>
    )

  return (
    <div className='tasks-lists'>
      <TasksList
        filterFunction={(task) =>
          task.flair === props.space.name && task.status !== 'done'
        }
        mode='dashboard'
      />
    </div>
  )
}

export default observer(TasksTool)
