import Space from '../model/Space'
import { accessQueryParameters } from './Authentication'
import { BackendError, ErrorType } from './Errors'

export const BASE_URL = 'https://infinity-spaces.azurewebsites.net'
export const DEFAULT_HEADERS = {
  headers: {
    'Content-Type': 'application/json',
  },
}

/**
 * Gets all spaces of the current organisation
 * @param organisationIdentifier ID of the organisation from which the app was started
 */
const listSpaces = async (organisationIdentifier: string): Promise<Space[]> => {
  const response = await fetch(
    `${BASE_URL}/spaces${accessQueryParameters(organisationIdentifier)}`,
    { method: 'GET', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.spaces) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.spaces as Space[]
}

/**
 * Creates a new space from a given payload
 * @param space New space that will be created
 * @param organisationIdentifier ID of the organisation from which the app was started
 */
const createSpace = async (
  space: Partial<Space>,
  organisationIdentifier: string
): Promise<Space> => {
  const response = await fetch(
    `${BASE_URL}/spaces${accessQueryParameters(organisationIdentifier)}`,
    { method: 'POST', ...DEFAULT_HEADERS, body: JSON.stringify(space) }
  )
  const body = await response.json()

  if (!response.ok || !body.space) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.space as Space
}

/**
 * Updates properties of an existing space
 * @param spaceId Identifier of the space that should be updated
 * @param changes Key-value store of properties that should be updated and their new values
 * @param organisationIdentifier ID of the organisation from which the app was started
 */
const updateSpace = async (
  spaceId: string,
  changes: Partial<Space>,
  organisationIdentifier: string
): Promise<Space> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceId}${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'PATCH', ...DEFAULT_HEADERS, body: JSON.stringify(changes) }
  )
  const body = await response.json()

  if (!response.ok || !body.space) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.space as Space
}

/**
 * Deletes an existing space
 * @param spaceId Identifier of the space that should be deleted
 * @param organisationIdentifier ID of the organisation from which the app was started
 */
const deleteSpace = async (
  spaceId: string,
  organisationIdentifier: string
): Promise<void> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceId}${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'DELETE', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.space) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
}

const Spaces = {
  listSpaces,
  createSpace,
  updateSpace,
  deleteSpace,
}

export default Spaces
