import { Flex, Icon, IconSize, Text, Tooltip } from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'

const DueDateText = (props: { date: string; isDone?: boolean }) => {
  const dueDate = DateTime.fromFormat(props.date, 'dd.LL.y')
  const today = DateTime.now().startOf('day')
  const tomorrow = DateTime.now().plus({ days: 1 })

  const isDueToday = dueDate >= today && dueDate <= today.endOf('day')
  const isDueTomorrow = dueDate > today && dueDate <= tomorrow.endOf('day')
  const isDueInTheNextSevenDays =
    dueDate > tomorrow && dueDate < today.plus({ week: 1 })

  const isOverdue = dueDate < today && !props.isDone

  let humanReadableDate = dueDate.toLocaleString({
    month: 'short',
    day: 'numeric',
    weekday: 'short',
  })

  if (isDueToday) {
    return (
      <Flex verticalAlignment='center' spacing='tiny'>
        <Icon icon='waiting' size={IconSize.Tiny} />
        <Text type='inline'>Heute fällig</Text>
      </Flex>
    )
  } else if (isOverdue) {
    return (
      <Tooltip content={`seit ${humanReadableDate}`}>
        <Text type='inline' variant='error'>
          Überfällig
        </Text>
      </Tooltip>
    )
  } else if (isDueTomorrow) {
    humanReadableDate = 'Morgen'
  } else if (isDueInTheNextSevenDays) {
    humanReadableDate = dueDate.weekdayLong
  }

  return <Text type='inline'>{humanReadableDate}</Text>
}

export default DueDateText
