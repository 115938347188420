import { NavigationItem } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router'
import { useRootStore } from '../../stores/RootStoreContext'

const SpaceNavigationItem = () => {
  const { spaceId } = useParams<{ spaceId: string }>()
  const { spacesStore } = useRootStore()

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  if (!space) return null

  return (
    <NavigationItem
      title={space.name}
      backButton={{ path: '/', text: 'Alle Spaces' }}
      tabs={[
        {
          description: 'Übersicht',
          path: '/space/' + space.id + '/overview',
        },
        {
          description: 'Beiträge',
          path: '/space/' + space.id + '/posts',
        },
        {
          description: 'Tasks',
          path: '/space/' + space.id + '/tasks',
        },
        {
          description: 'Dateien',
          path: '/space/' + space.id + '/files',
        },
      ]}
    />
  )
}

export default observer(SpaceNavigationItem)
