import { Avatar, Flex, NonIdealState, Text } from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Post from '../../model/Post'
import Space from '../../model/Space'
import Posts from '../../networking/Posts'
import { useRootStore } from '../../stores/RootStoreContext'
import './PostList.scss'

interface PostListProps {
  space: Space
  limit?: number
  mode?: 'default' | 'preview'
}

const PostList = (props: PostListProps) => {
  const { authenticationStore, organisationStore } = useRootStore()
  const [posts, setPosts] = useState<Post[]>([])

  useEffect(() => {
    if (!props.space || !authenticationStore.organisationIdentifier) return

    Posts.listPosts(
      authenticationStore.organisationIdentifier,
      props.space.id
    ).then((loadedPosts) => {
      if (props.limit && props.limit < loadedPosts.length) {
        setPosts(loadedPosts.slice(0, props.limit))
      } else {
        setPosts(loadedPosts)
      }
    })
  }, [])

  if (!props.space) return null

  return (
    <div className='post-list'>
      {posts.length === 0 && (
        <NonIdealState icon='chat'>Noch keine Beiträge</NonIdealState>
      )}
      {posts.map((post, index) => (
        <React.Fragment key={post.id}>
          {index > 0 && <div className='delimiter' />}
          <Link to={`/space/${props.space.id}/post/${post.id}`} key={post.id}>
            <Flex className='post-entry' verticalAlignment='center'>
              <Avatar name={organisationStore.fullNameOfMember(post.author)} />
              <Flex style={{ flexGrow: 1 }} direction='vertical'>
                {props.mode !== 'preview' && (
                  <Text type='inline' variant='subtle' className='author'>
                    {organisationStore.fullNameOfMember(post.author)}
                  </Text>
                )}
                <Text type='inline' className='title'>
                  {post.title}
                </Text>
              </Flex>
              <Text
                type='inline'
                className='date'
                variant='subtle'
                style={{ flexShrink: 0 }}
              >
                {DateTime.fromISO(post.createdAt).toLocaleString(
                  props.mode === 'preview'
                    ? {
                        month: 'short',
                        day: 'numeric',
                      }
                    : {
                        month: 'long',
                        day: 'numeric',
                        weekday: 'long',
                      }
                )}
              </Text>
            </Flex>
          </Link>
        </React.Fragment>
      ))}
    </div>
  )
}

export default observer(PostList)
