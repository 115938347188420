import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { persist } from 'mobx-persist'
import User from '../model/User'
import Authentication from '../networking/Authentication'
import RootStore from './RootStore'
import Store from './Store'

export default class AuthenticationStore extends Store {
  @persist('object') @observable currentUser: User | null = null

  @persist @observable applicationAccessToken: string | undefined = undefined
  @persist @observable userDataToken: string | undefined = undefined
  @persist @observable organisationIdentifier: string | null = null

  @observable isHydrated: boolean = false

  constructor(root: RootStore) {
    super(root)
    makeObservable(this)
  }

  @action
  setCurrentTokens(applicationAccessToken: string, userDataToken: string) {
    this.applicationAccessToken = applicationAccessToken
    this.userDataToken = userDataToken
    Authentication.setCurrentTokens(applicationAccessToken, userDataToken)
  }

  @action
  setOrganisationIdentifier(organisationIdentifier: string) {
    this.organisationIdentifier = organisationIdentifier
    this.rootStore.organisationStore.loadOrganisationInformation()
    this.rootStore.tasksStore.loadTasks()
  }

  @action
  onLogin() {
    this.loadUserData()
  }

  @computed
  get isAuthenticated(): boolean {
    return (
      this.applicationAccessToken !== undefined &&
      this.userDataToken !== undefined
    )
  }

  @action
  logout() {
    this.applicationAccessToken = undefined
    this.userDataToken = undefined
    this.currentUser = null
    this.organisationIdentifier = null

    this.rootStore.spacesStore.spaces = []
    this.rootStore.organisationStore.reset()
    this.rootStore.tasksStore.reset()
  }

  onHydrate() {
    if (this.applicationAccessToken && this.userDataToken) {
      Authentication.setCurrentTokens(
        this.applicationAccessToken,
        this.userDataToken
      )
      this.loadUserData()
    }
    this.isHydrated = true
  }

  @computed
  get userMenu() {
    return {
      name: this.currentUser?.firstname ?? '',
      allOrganisations:
        this.currentUser?.organisations.map((organisation) => ({
          id: organisation.IDForAPI!,
          name: organisation.name,
          onClick: () => {
            document.location.href = `https://staging.infinity.nextbusiness.ch/switch-organisation?id=${organisation.id}&app=ch.nextbusiness.infinity.spaces`
          },
        })) || [],
      currentOrganisation: {
        id: this.rootStore.organisationStore.currentIdentifier || 'none',
        name:
          this.rootStore.organisationStore.currentOrganisation?.name ||
          'Keine Organisation',
        onClick: () => {},
      },
    }
  }

  @action
  async loadUserData() {
    try {
      const user = await Authentication.retrieveCurrentUser()
      runInAction(() => {
        this.currentUser = user
        this.rootStore.spacesStore.loadSpaces()
      })
    } catch {
      this.logout()
    }
  }

  @computed
  get fullUserName(): string {
    if (this.currentUser) {
      return (
        this.currentUser.firstname +
        ' ' +
        this.currentUser.lastname
      ).trim()
    }
    return ''
  }
}
