import { Button, Card, Flex } from '@nextbusiness/infinity-ui'
import React from 'react'
import TaskModel from '../../model/Task'
import { useRootStore } from '../../stores/RootStoreContext'
import InlineEdit from '../input/InlineEdit'
import './Task.scss'
import TaskAssignee from './TaskAssignee'
import TaskDueDate from './TaskDueDate'
import TaskFlair from './TaskFlair'
import TaskGlances from './TaskGlances'
import TaskStatus from './TaskStatus'

export interface TaskProps {
  task: TaskModel
  mode?: TaskMode
  style?: TaskStyle

  hideAssignee?: boolean
  hideDueDate?: boolean
  hideFlair?: boolean
  hideActions?: boolean
}

export type TaskMode = 'default' | 'simple' | 'dashboard'
export type TaskStyle = 'default' | 'card'

const Task = (props: TaskProps) => {
  const { task } = props
  const { tasksStore } = useRootStore()

  const updateTask = async (changes: Partial<TaskModel>) => {
    await tasksStore.updateTaskWithId(task.id!, changes)
  }

  return (
    <Card
      className={
        'task' +
        (props.style === 'card' ? ' style-card' : '') +
        (props.mode === 'dashboard' ? ' dashboard-mode' : '')
      }
      key={task.id!}
      style={{ padding: '0 1rem' }}
    >
      <Flex
        verticalAlignment='center'
        horizontalAlignment='flex-start'
        className='task-inner'
        direction={props.style === 'card' ? 'vertical' : 'horizontal'}
      >
        <Flex verticalAlignment='center' style={{ flexGrow: 1 }}>
          <TaskStatus task={task} updateTask={updateTask} />
          <div style={{ flexGrow: 1, alignItems: 'center' }}>
            <InlineEdit
              className='task-title'
              initialValue={task.title}
              onCommit={(updatedTitle: string) =>
                updateTask({ title: updatedTitle })
              }
              disabled={props.mode === 'dashboard'}
              style={{ display: 'flex' }}
            />
            {props.mode === 'dashboard' && <TaskGlances task={task} />}
          </div>
        </Flex>
        <Flex
          horizontalAlignment='flex-end'
          verticalAlignment='center'
          style={{ flexGrow: 1 }}
        >
          {props.mode !== 'dashboard' && (
            <>
              {!props.hideDueDate && (
                <TaskDueDate task={task} updateTask={updateTask} />
              )}
              {!props.hideFlair && (
                <TaskFlair task={task} updateTask={updateTask} />
              )}
            </>
          )}
          {props.mode !== 'dashboard' && props.mode !== 'simple' && (
            <>
              {!props.hideAssignee && (
                <TaskAssignee task={task} updateTask={updateTask} />
              )}
              {!props.hideActions && (
                <Button
                  iconOnly='delete'
                  variant='tertiary'
                  onClick={() => tasksStore.deleteTaskWithId(task.id!)}
                  aria-label='Task löschen'
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  )
}

export default Task
