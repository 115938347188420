import React from 'react'
import { useLocation } from 'react-router-dom'

export const useQuery = () => new URLSearchParams(useLocation().search)

export const useAsyncEffect = (
  effect: (isMounted: () => boolean) => any | Promise<any>,
  destroy?: (result?: any) => void
) => {
  const hasDestroy = typeof destroy === 'function'

  React.useEffect(function () {
    var result: any
    var mounted = true
    var maybePromise = effect(function () {
      return mounted
    })

    Promise.resolve(maybePromise).then(function (value) {
      result = value
    })

    return function () {
      mounted = false

      if (hasDestroy && destroy) {
        destroy(result)
      }
    }
  })
}
