import File from '../model/File'
import { accessQueryParameters } from './Authentication'
import { BackendError, ErrorType } from './Errors'
import { BASE_URL, DEFAULT_HEADERS } from './Spaces'

const uploadFile = async (
  formData: FormData,
  organisationIdentifier: string,
  spaceIdentifier: string
): Promise<void> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/file${accessQueryParameters(
      organisationIdentifier
    )}`,
    {
      method: 'POST',
      body: formData,
    }
  )
  const body = await response.json()
  console.log(body)

  if (!response.ok || !body.file) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
}

const listFiles = async (
  organisationIdentifier: string,
  spaceIdentifier: string
): Promise<File[]> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/files${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'GET', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.files) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.files as File[]
}

const getFile = async (
  organisationIdentifier: string,
  spaceIdentifier: string,
  fileIdentifier: string
): Promise<File> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/file/${fileIdentifier}${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'GET', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.file) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.file as File
}

/**
 * Updates an existing post
 */
const updateFile = async (
  changes: Partial<File>,
  fileIdentifier: string,
  organisationIdentifier: string,
  spaceIdentifier: string
): Promise<File> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/file/${fileIdentifier}${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'PATCH', ...DEFAULT_HEADERS, body: JSON.stringify(changes) }
  )
  const body = await response.json()

  if (!response.ok || !body.file) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.file as File
}

/**
 * Deletes an existing post
 */
const deleteFile = async (
  fileIdentifier: string,
  organisationIdentifier: string,
  spaceIdentifier: string
): Promise<File> => {
  const response = await fetch(
    `${BASE_URL}/space/${spaceIdentifier}/post/${fileIdentifier}${accessQueryParameters(
      organisationIdentifier
    )}`,
    { method: 'DELETE', ...DEFAULT_HEADERS }
  )
  const body = await response.json()

  if (!response.ok || !body.file) {
    switch (body.message) {
      case 'Unauthorised':
        throw new BackendError(ErrorType.Unauthorised, body.message)
      default:
        throw new Error(body?.message)
    }
  }
  return body.file as File
}

const Files = {
  uploadFile,
  listFiles,
  getFile,
  updateFile,
  deleteFile,
}

export default Files
