import { Avatar, Button, Card, Heading, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory } from 'react-router'
import Space from '../../model/Space'
import { useRootStore } from '../../stores/RootStoreContext'
import './SpaceCard.scss'

interface SpaceCardProps {
  space: Space
}

const SpaceCard = (props: SpaceCardProps) => {
  const history = useHistory()
  const { organisationStore } = useRootStore()

  return (
    <Button
      variant='shell'
      className='space-card'
      onClick={() => history.push(`/space/${props.space.id}`)}
    >
      <Card>
        <Heading type='h3'>{props.space.name}</Heading>
        <Text type='paragraph' className='space-description'>
          {props.space.description}
        </Text>
        <div className='space-people'>
          {props.space.hasRestrictedAccess
            ? props.space.members?.map((member) => (
                <Avatar
                  name={organisationStore.fullNameOfMember(member)}
                  key={member}
                />
              ))
            : organisationStore.members
                ?.filter((member) => member.invitationStatus === 'accepted')
                .map((member) => (
                  <Avatar
                    name={organisationStore.fullNameOfMember(member.id!)}
                    key={member.id}
                  />
                ))}
        </div>
      </Card>
    </Button>
  )
}

export default observer(SpaceCard)
