import { Button, Card, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import TaskListSubpage from '../../base-components/tasks/TaskListSubpage'
import TasksBoard from '../../base-components/tasks/TasksBoard'
import { TasksFilterFunction } from '../../base-components/tasks/TasksList'
import { useRootStore } from '../../stores/RootStoreContext'
import { KeyValueStore } from '../../utility/types'
import './TasksPage.scss'

const tasksFilterForViewOption = (viewOption: string): TasksFilterFunction => {
  switch (viewOption) {
    case 'done':
      return (task) => task.status === 'done'
    default:
      return (task) => task.status !== 'done'
  }
}

const VIEW_OPTIONS: KeyValueStore = {
  default: 'Anstehende Tasks',
  done: 'Abgeschlossen',
  board: 'Team-Board',
}

const TasksPage = () => {
  const { spaceId } = useParams<{ spaceId: string }>()
  const { spacesStore } = useRootStore()

  const [viewOption, setViewOption] = useState<string>('default')

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  if (!space) return null

  return (
    <div className='space-card-page tasks-page'>
      <Card elevation='medium' className='space-subpage'>
        <div className='subpage-container'>
          <Flex verticalAlignment='center' direction='vertical'>
            <div className='view-options'>
              <Flex spacing='tiny'>
                {Object.keys(VIEW_OPTIONS).map((option) => (
                  <Button
                    variant={viewOption === option ? 'secondary' : 'quaternary'}
                    onClick={() => setViewOption(option)}
                  >
                    {VIEW_OPTIONS[option]}
                  </Button>
                ))}
              </Flex>
            </div>
          </Flex>
        </div>
        {viewOption === 'board' ? (
          <div className='tasks-inline-board'>
            <TasksBoard
              tasksListProps={{ hideFlair: true }}
              filterFunction={(task) => task.flair === space.name}
            />
          </div>
        ) : (
          <div className='subpage-container'>
            <TaskListSubpage
              filterFunction={(task) =>
                task.flair === space.name &&
                tasksFilterForViewOption(viewOption)(task)
              }
              hasComposer={viewOption !== 'done'}
              taskProps={{ hideFlair: true }}
              predefinedTaskDetails={{ flair: space.name }}
            />
          </div>
        )}
      </Card>
    </div>
  )
}

export default observer(TasksPage)
