import { Button, Card, Flex } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import { useRootStore } from '../../stores/RootStoreContext'
import PostList from './PostList'
import './PostsPage.scss'

const PostsPage = () => {
  const { spaceId } = useParams<{ spaceId: string }>()
  const { spacesStore } = useRootStore()
  const history = useHistory()

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  if (!space) return null

  return (
    <div className='space-card-page posts-page'>
      <Card elevation='medium' className='space-subpage'>
        <div className='subpage-container'>
          <Flex verticalAlignment='center' direction='vertical'>
            <Button
              iconLeft='add'
              variant='primary'
              onClick={() => history.push(`/space/${spaceId}/posts/new`)}
            >
              Neuer Beitrag
            </Button>
          </Flex>
          <PostList space={space} />
        </div>
      </Card>
    </div>
  )
}

export default observer(PostsPage)
