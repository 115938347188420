import { Button, Card, Flex, Spacer, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import { useRootStore } from '../../stores/RootStoreContext'
import FilesList from '../files/FilesList'
import PostList from '../posts/PostList'
import TasksTool from '../tasks/TasksTool'
import './SpacePage.scss'
import SpaceTeamMembers from './SpaceTeamMembers'

const SpacePage = () => {
  const { spaceId } = useParams<{ spaceId: string }>()
  const { spacesStore, tasksStore } = useRootStore()
  const history = useHistory()

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  if (!space) return null

  return (
    <div className='space-card-page space-page'>
      <Card elevation='medium' className='space-subpage with-top-bar-actions'>
        <SpaceTeamMembers space={space} />
        <Flex
          className='space-title'
          horizontalAlignment='center'
          spacing='regular'
        >
          <div className='space-identity'>
            <Text type='paragraph'>{space.description}</Text>
          </div>
        </Flex>
        <Flex spacing='small' horizontalAlignment='center'>
          <Card className='space-tool-window' elevation='none'>
            <div className='space-tool-window-header'>
              <Button
                iconLeft='chat'
                variant='quaternary'
                onClick={() => history.push(`/space/${spaceId}/posts`)}
              >
                Beiträge
              </Button>
            </div>
            <PostList space={space} limit={3} mode='preview' />
          </Card>
          <Card className='space-tool-window' elevation='none'>
            <div className='space-tool-window-header'>
              <Button
                iconLeft='inspection'
                variant='quaternary'
                disabled={!tasksStore.hasTasksPermission}
                onClick={() => history.push(`/space/${spaceId}/tasks`)}
              >
                Tasks
              </Button>
            </div>
            <TasksTool space={space} />
          </Card>
          <Card className='space-tool-window' elevation='none'>
            <div className='space-tool-window-header'>
              <Button
                iconLeft='folder'
                variant='quaternary'
                onClick={() => history.push(`/space/${spaceId}/files`)}
              >
                Dateien
              </Button>
            </div>
            <FilesList space={space} limit={8} />
          </Card>
        </Flex>
        <Spacer />
      </Card>
    </div>
  )
}

export default observer(SpacePage)
