import React from 'react'
import Task from '../../model/Task'
import { TaskProps } from './Task'
import TasksComposer from './TasksComposer'
import TasksList, {
  PredefinedTasksFilterFunction,
  TasksFilterFunction,
} from './TasksList'

const TaskListSubpage = (props: {
  filterFunction: TasksFilterFunction | PredefinedTasksFilterFunction
  hasComposer?: boolean
  predefinedTaskDetails?: Partial<Task>
  taskProps?: Partial<TaskProps>
}) => (
  <div className='tasks-container'>
    {props.hasComposer && (
      <TasksComposer predefinedTaskDetails={props.predefinedTaskDetails} />
    )}
    <TasksList
      filterFunction={props.filterFunction}
      {...(props.taskProps || {})}
    />
  </div>
)

export default TaskListSubpage
