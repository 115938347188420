import { Avatar, Flex, Spacer, Text } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import { useRootStore } from '../../stores/RootStoreContext'
import './TasksBoard.scss'
import TasksList, { TasksFilterFunction, TasksListProps } from './TasksList'

interface TasksBoardProps {
  tasksListProps?: TasksListProps
  filterFunction?: TasksFilterFunction
}

const TasksBoard = (props: TasksBoardProps) => {
  const { organisationStore } = useRootStore()

  return (
    <Flex className='tasks-board'>
      {organisationStore.members
        ?.filter((member) => member.invitationStatus === 'accepted')
        .map((member) => {
          const fullName = organisationStore.fullNameOfMember(member.id!)
          return (
            <div className='board-column'>
              <Flex
                verticalAlignment='center'
                className='board-column-header'
                spacing='tiny'
              >
                <Avatar name={fullName} />
                <Text type='inline'>{fullName}</Text>
              </Flex>
              <div className='column-tasks'>
                <TasksList
                  style='card'
                  hideAssignee
                  hideActions
                  {...props.tasksListProps}
                  filterFunction={(task) =>
                    task.assignee === member.id &&
                    task.status !== 'done' &&
                    (props.filterFunction ? props.filterFunction(task) : true)
                  }
                />
                <Spacer size='regular' />
              </div>
            </div>
          )
        })}
      <Spacer
        size='regular'
        direction='horizontal'
        style={{ height: '1rem' }}
      />
    </Flex>
  )
}

export default observer(TasksBoard)
