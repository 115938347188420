import Delimiter from '@editorjs/delimiter'
import Header from '@editorjs/header'
import InlineCode from '@editorjs/inline-code'
import List from '@editorjs/list'
import Marker from '@editorjs/marker'
import Paragraph from '@editorjs/paragraph'
import Table from '@editorjs/table'

export const EDITOR_JS_TOOLS = {
  header: {
    class: Header,
    inlineToolbar: ['marker', 'link'],
    config: {
      placeholder: 'Header',
    },
    shortcut: 'CMD+SHIFT+H',
  },
  list: {
    class: List,
    inlineToolbar: true,
    shortcut: 'CMD+SHIFT+L',
  },
  marker: {
    class: Marker,
    shortcut: 'CMD+SHIFT+M',
    inlineToolbar: true,
  },
  delimiter: Delimiter,
  inlineCode: {
    class: InlineCode,
    shortcut: 'CMD+SHIFT+C',
  },
  table: {
    class: Table,
    inlineToolbar: true,
    shortcut: 'CMD+ALT+T',
  },
  paragraph: { class: Paragraph, inlineToolbar: true },
} as { [toolName: string]: any }
