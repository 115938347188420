import { Icon, InputField, Tooltip } from '@nextbusiness/infinity-ui'
import { DateTime } from 'luxon'
import React from 'react'
import InlineEdit from '../input/InlineEdit'
import TaskModel from '../../model/Task'
import DueDateText from './DueDateText'

interface TaskDueDateProps {
  task: TaskModel
  updateTask: (changes: Partial<TaskModel>) => Promise<void>
}

const TaskDueDate = ({ task, updateTask }: TaskDueDateProps) => (
  <InlineEdit
    mode='popup'
    initialValue={
      task.dueDate
        ? DateTime.fromISO(task.dueDate as string).toFormat('dd.LL.y')
        : ''
    }
    renderValue={(date: string) =>
      date ? (
        <DueDateText date={date} isDone={task.status === 'done'} />
      ) : (
        <Tooltip content='Fälligkeit setzen'>
          <Icon icon='schedule' size={20} />
        </Tooltip>
      )
    }
    inputControl={<InputField value='' />}
    onCommit={async (date: string) => {
      await updateTask({
        dueDate: DateTime.fromFormat(date, 'dd.LL.y').toMillis(),
      })
    }}
    className='task-date'
  />
)

export default TaskDueDate
