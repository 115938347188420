import { Avatar, Option, Select, Tooltip } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React from 'react'
import InlineEdit from '../input/InlineEdit'
import TaskModel from '../../model/Task'
import { useRootStore } from '../../stores/RootStoreContext'

interface TaskAssigneeProps {
  task: TaskModel
  updateTask: (changes: Partial<TaskModel>) => Promise<void>
}

const TaskAssignee = ({ task, updateTask }: TaskAssigneeProps) => {
  const { organisationStore } = useRootStore()
  return (
    <InlineEdit
      mode='popup'
      initialValue={{
        value: task.assignee || null,
        label: task.assignee
          ? organisationStore.fullNameOfMember(task.assignee)
          : 'Nicht zugewiesen',
      }}
      inputControl={
        <Select
          options={[
            { value: null, label: 'Nicht zugewiesen' },
            ...organisationStore.membersAsOptions,
          ]}
        />
      }
      renderValue={(assignee: Option) =>
        assignee.value ? (
          <Avatar name={assignee.label as string} />
        ) : (
          <Tooltip content='Task zuweisen'>
            <div className='ui-avatar unassigned'></div>
          </Tooltip>
        )
      }
      onCommit={async (option: Option) => {
        await updateTask({ assignee: option.value as string })
      }}
      className='task-assignee'
    />
  )
}

export default observer(TaskAssignee)
