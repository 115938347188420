import { Button, Card, Flex, Spacer } from '@nextbusiness/infinity-ui'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import { useRootStore } from '../../stores/RootStoreContext'
import FilesList from './FilesList'

const FilesPage = () => {
  const { spaceId } = useParams<{ spaceId: string }>()
  const { spacesStore } = useRootStore()
  const history = useHistory()

  const space = spacesStore.spaces.find((spaceItem) => spaceItem.id === spaceId)
  if (!space) return null

  return (
    <div className='space-card-page files-page'>
      <Card elevation='medium' className='space-subpage'>
        <div className='subpage-container'>
          <Flex verticalAlignment='center' direction='vertical'>
            <Button
              iconLeft='add'
              variant='primary'
              onClick={() => history.push(`/space/${spaceId}/files/new`)}
            >
              Datei hochladen
            </Button>
          </Flex>
          <Spacer />
          <FilesList space={space} />
        </div>
      </Card>
    </div>
  )
}

export default FilesPage
