import { Button, Flex, InputField } from '@nextbusiness/infinity-ui'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import Task from '../../model/Task'
import Tasks from '../../networking/Tasks'
import { useRootStore } from '../../stores/RootStoreContext'

interface TasksComposerProps {
  predefinedTaskDetails?: Partial<Task>
}

const TasksComposer = (props: TasksComposerProps) => {
  const { tasksStore, organisationStore } = useRootStore()
  const [taskTitle, setTaskTitle] = useState<string>('')

  const addTaskToInbox = async () => {
    await Tasks.addTask(organisationStore.currentIdentifier!, {
      title: taskTitle,
      status: 'icebox',
      ...(props.predefinedTaskDetails ?? {}),
    })
    tasksStore.loadTasks()
    setTaskTitle('')
  }

  return (
    <div className='tasks-composer'>
      <Flex verticalAlignment='center' spacing='small'>
        <InputField
          value={taskTitle}
          onChange={setTaskTitle}
          label='Neue Aufgabe'
          fullWidth
        />
        {taskTitle && (
          <Button iconLeft='add' onClick={addTaskToInbox}>
            Hinzufügen
          </Button>
        )}
      </Flex>
    </div>
  )
}

export default observer(TasksComposer)
